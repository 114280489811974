import * as React from 'react'
import { Link } from 'gatsby'
//import { StickyContainer, Sticky } from 'react-sticky';

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import TopDiv from "../components/index/TopDiv";
import CenteredDiv from "../components/index/CenteredDiv";
import RightDiv from "../components/index/RightDiv";
import MidDiv from "../components/index/MidDiv";
import BottomDiv from "../components/index/BottomDiv";
import Interior from "../components/rooms/Interior";
import EasyBooking from "../components/booking/BookingText";
import styled from '@emotion/styled'
import HeaderDesktop from "../components/HeaderDesktop";
import StudioPlx from "../components/swipesliders/StudioPlx";
import StudioInfo from "../components/rooms/Studio";
import StudioPlusPlx from "../components/swipesliders/StudioPlusPlx";
import StudioPlusInfo from "../components/rooms/StudioPlus";
import SuitePlx from "../components/swipesliders/SuitePlx";
import SuiteInfo from "../components/rooms/Suite";





const IndexPage = ( {pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <HeaderDesktop></HeaderDesktop>


    <StudioPlx/>
    <StudioInfo/>
    <StudioPlusPlx/>
    <StudioPlusInfo/>
    <SuitePlx/>
    <SuiteInfo/>

  </IndexLayout>
)

export default IndexPage
