import * as React from 'react'
import styled from '@emotion/styled'

import { Parallax, Background } from 'react-parallax';
import { SuitePlxImg } from '../rooms/RoomsImg'

const img_slider_2 = require('../../../assets/images/V7_092_Buchen.jpg');


interface SuitePlxProps {
  className?: string
}

const params = {
  speed:9000,
  watchSlidesVisibility: true,
  loop:true,
  loopedSlides:1.2,
  slidesPerView:1,
  spaceBetween: 0,
  autoplay: {
    delay: 1000,
  },


};

const Div = styled.div`
width:80%;
margin-left:auto;
margin-right:auto;

  @media screen and (max-width: 1200px) {
width:100%;

   }

`

const PlxDiv = styled.div`
height:460px;

  @media screen and (max-width: 1000px) {
height:440px;

   }


  @media screen and (min-width: 1400px) {
height:500px;

   }

     @media screen and (min-width: 1600px) {
height:540px;

   }

      @media screen and (min-width: 1800px) {
height:600px;

   }

   @media screen and (max-width: 959px) {
display:none;

   }
`


const Img = styled.img`
width:100%;
height:auto;
display:none;

   @media screen and (max-width: 959px) {
display:block;

   }

`
const SuitePlx: React.FC<SuitePlxProps> = ({ }) => (
<Div id={'suite'}>
  <SuitePlxImg/>
  <Parallax
    bgImage={img_slider_2}
    bgImageAlt=""
      strength={240}
  >
    <PlxDiv />
  </Parallax>
</Div>
)

export default SuitePlx
