import * as React from 'react'
import styled from '@emotion/styled'
import {Link} from "gatsby";
import {fonts} from "../../styles/variables";
import { FormattedMessage } from 'react-intl';
import LocalizedLink from "../int/LocalizedLink";


const StyledStudioPlusInfo = styled.div`

  width: 363px;
display: flex;
margin-left:auto;
margin-right:auto;
margin-top:1em;
margin-bottom:3em;
align-items:center;
justify-content: center;
font-family: "Theano Didot", Regular;
color:rgb(68, 68, 68);

  @media screen and (max-width: 370px) {
  width:100%;

   }


`;

const StyledP = styled.p`
font-family: "Theano Didot", Regular;
font-style: italic;
font-size: 200%;
text-align: center !important;
line-height: 40px;
letter-spacing: 2px;
color:rgb(68, 68, 68);
width:100%;


`;

const Einzug = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
line-height: 1.5em;
color:rgb(68, 68, 68);
text-indent: 20px;
color:rgb(68, 68, 68);
margin-bottom:0;
`;

const P = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
line-height: 1.5em;
color:rgb(68, 68, 68);
color:rgb(68, 68, 68);
margin-bottom:0;


`;

const PBold = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
line-height: 1.5em;
color:rgb(68, 68, 68);
color:rgb(68, 68, 68);
margin-bottom:0;
font-weight:bold;


`;



const NextButton = styled.div`
cursor:pointer;
font-weight:700;
width:98%;
margin-bottom:10px;
margin-top:20px;
height:48px;
 display: flex;
 align-items:center;
 justify-content:center;
  border-radius: 10rem;
  color: #fff;
  font-size: 1rem;
  //letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight:bold;
  font-size:21px;
margin-left:auto;
margin-right:auto;
font-family:${fonts.button};
letter-spacing:2px;
text-transform:uppercase;



:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #188cec;
  transition: all .3s;
  border-radius: 10rem;
  z-index: -1;
}

 :after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #54aaf1;
  border-radius: 10rem;
  z-index: -2;
}

:hover {
  color: #fff;
}

:hover:before {
  width: 100%;
}


   @media screen and (max-width: 1295px) {
z-index:999999;
}


`

const A = styled.div`
display:flex;
justify-content:center;
align-items:center;
color:white;
`

const Div = styled.div`
width:100%;
text-align:center;
color:rgb(68,68,68) !important;
opacity:1 !important;

:hover{

${NextButton}{
opacity:1;
}

}

@media screen and (max-width:500px){
text-align:left;
width:87%;
margin-left:auto;
margin-right:auto;

}

`

const StyledLink = styled(LocalizedLink)`
color:rgb(68,68,68) !important;
opacity:0.9 !important;

:hover{
color:rgb(68,68,68) !important;
opacity:1 !important;
}
`

interface StudioPlusInfoProps {
  className?: string
}

const StudioPlusInfo: React.FC<StudioPlusInfoProps> = ({ }) => (
  <StyledStudioPlusInfo>

    <Div>
      <StyledP>Studio Plus </StyledP>
    <PBold><FormattedMessage id="studio_plus_text_1" /></PBold>
    <PBold><FormattedMessage id="studio_plus_text_2" /> </PBold>
    <PBold> <FormattedMessage id="studio_plus_text_3" /> </PBold>
      <br/>
      <P><FormattedMessage id="studio_plus_text_4" /></P>
      <P><FormattedMessage id="studio_plus_text_5" /></P>
      <P><FormattedMessage id="studio_plus_text_6" /></P>
      <P><FormattedMessage id="studio_plus_text_7" /></P>
      <P><FormattedMessage id="studio_plus_text_8" /></P>
      <P><FormattedMessage id="studio_plus_text_9" /></P>
      <P><FormattedMessage id="studio_plus_text_10" /></P>
      <P><FormattedMessage id="studio_plus_text_11" /></P>
      <StyledLink to={'/buchen'}>  <NextButton><A><FormattedMessage id="btn_book" /></A></NextButton></StyledLink>

    </Div>



  </StyledStudioPlusInfo>

)

export default StudioPlusInfo
